/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 94번째 로그에서는 노션 한글 지원, 모질라 정리 해고, 데이터독 대시 2020에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "노션 한글 지원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/What-s-New-157765353f2c4705bd45474e5ba8b46c"
  }, "What's New? # August 10, 2020 — Notion in Korean 🇰🇷🎊🎊")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://toon.at/ko/index"
  }, "투네이션 - 당신을 향한 모두의 마음")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=iZKmDC1nf4w"
  }, "노션 한국어판 런칭 커뮤니티 이벤트 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.protocol.com/amp/notion-app-korea-2646919688?__twitter_impression=true"
  }, "Inside Notion's global expansion plan")), "\n"), "\n", React.createElement(_components.h3, null, "모질라 정리 해고 소식"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2020/08/11/mozilla-lays-off-250/"
  }, "Mozilla lays off 250 | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Mozilla"
  }, "Mozilla - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Mozilla_Corporation"
  }, "Mozilla Corporation - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.rust-lang.org/"
  }, "Rust Programming Language")), "\n"), "\n", React.createElement(_components.h3, null, "도시바 노트북 사업 중지"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bbc.com/korean/news-53734575?at_campaign=64&at_custom1=%5Bpost+type%5D&at_custom3=BBC+News+Korea&at_medium=custom7&at_custom4=9A96E3CC-DBAB-11EA-9E6F-018C4D484DA4&at_custom2=facebook_page"
  }, "일본: 도시바 노트북...35년 만에 역사의 뒤안길로 - BBC News 코리아")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23845596"
  }, "코닥의 바이오 야망, 스캔들로 드러나나...주가 28% 추락 - 중앙일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.upinews.kr/newsView/upi202002240062"
  }, "현대백화점, '코닥' 의류 매장 오픈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dbr.donga.com/article/view/1901/article_no/6566/ac/magazine"
  }, "필름시장이 죽어도 후지는 계속됐다 주인 같은 전문경영인의 개혁 있었기에… | 케이스스터디 | DBR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/playlist?list=PLIUCBpK1dpsPvOXp3Ps1etR8upMm27L0_"
  }, "Startup53 - YouTube")), "\n"), "\n", React.createElement(_components.h3, null, "인텔 해킹으로 민감데이터 유출"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.techtimes.com/articles/251627/20200806/intel-hacked-20gb-data-including-secret-files-accessble-using-password.htm"
  }, "Intel Hacked: 20GB of Data Including Secret Files Accessible Using Password 'Intel123' Leak Online | Tech Times")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/lightsail/"
  }, "Amazon Lightsail")), "\n"), "\n", React.createElement(_components.h3, null, "데이터독 Dash 2020"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/keyword/datadog"
  }, "데이터독(Datadog)이란? | 44BITS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.dashcon.io/2020/"
  }, "Dash | Scale up, speed up.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/security-hub/?aws-security-hub-blogs.sort-by=item.additionalFields.createdDate&aws-security-hub-blogs.sort-order=desc"
  }, "AWS Security Hub | Amazon Web Services (AWS)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/config/"
  }, "AWS Config")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.openpolicyagent.org/"
  }, "Open Policy Agent")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hopin.to/"
  }, "Hopin: Online venue for virtual events")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.mudfestival.or.kr/festival/view"
  }, "보령머드축제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.yahoo.com/quote/DDOG/"
  }, "Datadog, Inc. (DDOG) Stock Price, News, Quote & History - Yahoo Finance")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.yahoo.com/quote/OKTA?p=OKTA&.tsrc=fin-srch"
  }, "Okta, Inc. (OKTA) Stock Price, News, Quote & History - Yahoo Finance")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.yahoo.com/quote/CRWD?p=CRWD&.tsrc=fin-srch"
  }, "CrowdStrike Holdings, Inc. (CRWD) Stock Price, News, Quote & History - Yahoo Finance")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.okta.com/resources/access-management-leader-gartner-magic-quadrant/"
  }, "Gartner Names Okta a Leader in Access Management Magic Quadrant | Okta")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.keycloak.org/"
  }, "Keycloak")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
